* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

html {
  height: -webkit-fill-available;
}

/* Vars */

:root {
  --radius: 42vmin;
  --button-text: calc(14 * (var(--radius) / 100));
  --button-padding: calc(6 * (var(--button-text) / 100))
    calc(16 * (var(--button-text) / 100));
  --base: calc(var(--radius) / 3.14);
  --button-transform: calc(var(--radius) / 2 + (var(--button-text) / 3));
  /* Colors */
  --bg-blue: #253555;
  --bg-blue-light: #c5f1ff;
  --text-off-white: rgb(239, 254, 255);
}

/* Utils */

.capitalize {
  text-transform: capitalize;
}

.hidden {
  display: none;
}

.inline {
  max-height: 50px;
  display: inline;
}

.overlay {
  position: absolute;
  top: 0.8vh;
  left: 1vw;
  height: 98.4vh;
  width: 98vw;
  background-color: rgba(26, 26, 26, 0.95);
  border-radius: 0.5em;
  box-shadow: 0 0 2em 0px black;
  z-index: 900;
}

/* Theme Customizations */

.themed-button {
  min-width: 1.42em;
  border-radius: 5.5%/18.5%;
  background: rgba(21, 36, 53, 0.8);
  color: var(--bg-blue-light);
  padding: var(--button-padding);
  box-shadow: 0 0 0.5em -0.13em black;
  font-size: calc(10.2 * var(--button-text) / 10);
  border: 1.8px solid rgb(127 229 204 / 29%);
}

.Skip-button:hover,
.navigation + .settings button:hover,
.themed-button:hover {
  background: rgb(7, 17, 34, 0.8);
  transform: scale(1.08);
  box-shadow: 0 0 0.5em 0.02em rgb(0 0 0 / 60%);
  /* padding: calc(1em / 4); */
  /* margin-top: 0.2em;
  margin-right: 0.2em; */
}

.Skip-button:hover {
  color: white;
  text-shadow: -2px -2px 0 rgb(160, 0, 0), 2px -2px 0 rgb(160, 0, 0),
    -2px 2px 0 rgb(160, 0, 0), 2px 2px 0 rgb(160, 0, 0);
}

.navigation + .settings button:hover {
  box-shadow: 0 0 0px 0px rgb(0, 0, 0);
}

.Skip-button:active,
.navigation + .settings button:active,
.themed-button:active {
  outline: 0 !important;
  position: relative;
  transform: scale(0.92) !important;
  box-shadow: 0 0 0.7em 0.07em black;
}

.navigation + .settings button:focus,
.Skip-button:focus,
.themed-button:focus {
  outline: 0 !important;
  border: 1px solid rgb(134, 177, 18);
  /* background-color: rgb(5, 63, 41); */
  color: cornsilk;
  transition: border 1ms;
}

.navigation + .settings button,
.Skip-button,
.themed-button {
  will-change: transform;
  transition: 100ms ease, 380ms ease, 400ms ease, 600ms, 520ms;
  transition-delay: 10ms, 90ms, 100ms, 100ms, 100ms;
  transition-property: border, transform, box-shadow, background, color;
}

.Skip-button:hover,
.Skip-button:active,
.Skip-button:focus,
.themed-button:hover,
.themed-button:active,
.themed-button:focus,
.navigation + .settings button:hover,
.navigation + .settings button:active,
.navigation + .settings button:focus {
  transition: 50ms ease, 50ms ease, 50ms ease, 250ms ease-in-out;
  transition-delay: 0ms, 0ms, 0ms, 0ms;
  transition-property: border, transform, box-shadow, background;
}
/* Page Styles */

.App {
  width: 100vw;
  height: 100vh;
  background-color: var(--bg-blue);
  text-align: center;
  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 8vh calc(100vh - 8vh - 2.5em) 2.5em;
  grid-template-areas:
    'brand nav settings'
    'main main main'
    'footer footer footer';
}

.App-header {
  grid-area: brand;
  height: 100%;
}

.App-header .brand {
  z-index: 950;
}

.App-main {
  background-color: var(--bg-blue);
  font-size: var(--button-text);
  color: white;
  grid-area: main;
}

.App-link {
  color: var(--bg-blue-light);
}

.App-footer {
  height: 100%;
  font-size: 0.9em;
  color: rgba(187, 187, 139, 0.514);
  grid-area: footer;
  height: 100%;
  display: grid;
  grid-template-areas: 'credit brand';
  grid-template-columns: 65% auto;
}

.App-footer .credit {
  font-weight: 500;
  font-style: oblique;
}

.brand {
  grid-area: brand;
  height: 100%;
  font-size: 12px;
  margin-left: 2vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  color: white;
}

.App-logo {
  max-height: 2em !important;
  border-radius: 20%;
}

.navigation.navbuttons {
  grid-area: nav;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: center;
}

.navigation .nav-btn {
  display: inline-flex;
  width: auto;
  margin: 0 0.5em;
  list-style: none;
  align-items: center;
  /* outline: 1px solid rgba(181, 239, 241, 0.6); */
}

.navigation .nav-btn:active {
  background-color: rgba(181, 239, 241, 0.6);
}

.navigation .nav-btn a:-webkit-any-link {
  text-decoration: none;
}

.nav-btn a {
  font-size: 3.5vh;
  /* align-self: center; */
}

.brand a,
.brand a:visited,
.nav-btn a,
.nav-btn a:visited {
  color: var(--text-off-white);
  text-decoration: none;
}

.navigation + .btn-wrapper.settings {
  grid-area: settings;
  margin-top: 1vw;
  margin-right: 1vw;
  justify-self: self-end;
}

.navigation + .settings .settings-button {
  z-index: 5;
  font-size: 4.6vh;
  border-radius: 25%;
  padding: 0vh 0vh 0.08vh 0.03vh;
  box-shadow: 0 0 50px -6px rgb(0, 0, 0);
  background-color: var(--bg-blue);
  border: 0 !important;
}

.Logo-title {
  margin: auto;
  margin-left: 0.1em;
}

.Title-bar .Logo-title,
.Footer-bar .Logo-title {
  font-size: calc(18px + 2vmin) !important;
}

.reflection {
  opacity: 0.2;
  grid-area: brand;
  transform: rotate(180deg);
}

.App-footer .credit {
  grid-area: credit;
  margin: auto 2vmin;
  text-align: right;
}

.App-footer :nth-last-child(1) {
  margin-right: 2vw;
}

.credit a,
.credit a:visited {
  font-size: 0.9em;
  /* font-weight: 100; */
  color: inherit;
  text-shadow: 4px 4px 10px rgba(1, 2, 1, 0.596);
}

.credit a:hover,
.credit a:active,
.credit a:focus {
  color: rgb(197, 197, 174);
}

.menu-btn-close {
  font-size: 4.6vmax;
  grid-area: X;
  background-color: rgb(109, 24, 24);
  border-radius: 0.25em;
  color: white;
}

.menu-btn-close:hover,
.menu-btn-close:active {
  background-color: rgb(65, 12, 12);
}

@media (prefers-reduced-motion) {
  .navigation + .settings .settings-button,
  .Skip-button,
  .themed-button {
    transition: 10ms ease, 40ms ease, 40ms ease;
    transition-delay: 10ms, 12ms, 10ms;
    transition-property: border, transform, box-shadow;
  }

  .Skip-button:hover,
  .Skip-button:active,
  .Skip-button:focus,
  .themed-button:hover,
  .themed-button:active,
  .themed-button:focus,
  .navigation + .settings .settings-button:hover,
  .navigation + .settings .settings-button:active,
  .navigation + .settings .settings-button:focus {
    transition: all 50ms;
    transition-delay: 0ms;
  }

  .navigation + .settings .settings-button {
    transition: all 0s;
    transition-delay: 0ms;
  }
}

@media screen and (-webkit-min-device-pixel-ratio: 2) {
  .App {
    /* background-color: lime; */
  }
}

@media only screen and (min-device-width: 400px) and (orientation: landscape) {
  .App-footer .credit {
    text-align: right;
  }
}
