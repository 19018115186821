@media only screen and (max-device-width: 4000px) and (orientation: landscape) {
  :root {
    --radius: 30vmax !important;
    --whitekey-width: calc(50vmax / 7.5);
    --whitekey-height: calc(var(--whitekey-width) * 3.4);
    --blackkey-width: calc(var(--whitekey-width) * 0.64);
    --blackkey-height: calc(var(--whitekey-height) * 0.55);
  }

  .App {
    grid-template-columns: auto;
    grid-template-rows: 3em auto 3em;
  }

  .question-container {
    align-self: center;
  }

  .question-container h3 {
    font-size: 3.5vmax;
  }

  .image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    grid-area: img;
  }

  .image-container .key-img {
    height: unset;
    max-height: 90%;
    max-width: 90%;
  }

  .score-container {
    grid-area: score;
  }

  .input-container {
    display: flex;
    align-items: center;
  }

  .input-container .piano {
    max-width: 90%;
  }

  .piano-key.white {
  }

  .piano-key.white .white-btn {
    /* width: 99%; */
  }

  .piano-key.black .black-btn {
    /* width: calc(52vw / 12); */
  }
}

@media only screen and (max-device-width: 767px) and (orientation: portrait) {
  .App-main .child:first-child {
  }

  .question-container {
  }

  .question-container .question {
  }

  .image-container {
  }

  .circleOfFifths {
  }
}

@media only screen and (min-device-width: 768px) and (orientation: portrait) {
  :root {
    --radius: 35vmin !important;
  }

  .question-container .question {
    margin-bottom: -1.5vh;
  }
}
