/*  Keyboard variables */

:root {
  --whitekey-width: calc(90vmin / 7);
  --whitekey-height: calc(var(--whitekey-width) * 3.2);
  --blackkey-width: calc(var(--whitekey-width) * 0.64);
  --blackkey-height: calc(var(--whitekey-height) * 0.55);
}

@media only screen and (max-device-width: 4000px) and (orientation: portrait) {
  .Quiz-main {
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 28% 11% 9% auto 20vw;
    grid-template-areas:
      'imgl imgc'
      'skip imgc'
      'question question'
      'volume volume'
      'input input';
  }
}

.sound-options {
  display: inline-flex;
}

.sound-options .volume.slider-wrapper label input {
  display: block;
}

.sound-checkbox,
.volume.slider-wrapper {
  grid-area: volume;
  font-size: 0.7em;
  margin: 0.3em;
}

.piano .loser {
  border-style: none;
  border-width: 0;
}

.piano .loser button {
  background-color: rgb(165, 22, 22) !important;
}

.piano .loser button.black-btn {
  background-color: rgb(168, 85, 85) !important;
}

.keyboard-container .piano {
  display: grid;
  max-height: var(--whitekey-height);
  height: min-content;
  max-width: 90vw;
  min-width: 8em;
  grid-template-columns: repeat(42, 1fr);
  grid-template-rows: var(--whitekey-height) var(--whitekey-height);
}

.piano .key-0,
.piano .key-2,
.piano .key-4,
.piano .key-5,
.piano .key-7,
.piano .key-9,
.piano .key-11 {
  grid-row-start: 2;
  grid-row-end: 2;
}

.piano .key-0 {
  grid-column-start: 1;
  grid-column-end: 7;
}
.piano .key-2 {
  grid-column-start: 7;
  grid-column-end: 13;
}
.piano .key-4 {
  grid-column-start: 13;
  grid-column-end: 19;
}
.piano .key-5 {
  grid-column-start: 19;
  grid-column-end: 25;
}
.piano .key-7 {
  grid-column-start: 25;
  grid-column-end: 31;
}
.piano .key-9 {
  grid-column-start: 31;
  grid-column-end: 37;
}
.piano .key-11 {
  grid-column-start: 37;
  grid-column-end: 43;
}

// Black keys
.piano .key-1,
.piano .key-3,
.piano .key-6,
.piano .key-8,
.piano .key-10 {
  grid-row-start: 2;
  grid-row-end: 2;
}

.piano .key-1 {
  grid-column-start: 5;
  grid-column-end: 9;
}
.piano .key-3 {
  grid-column-start: 11;
  grid-column-end: 15;
}
.piano .key-6 {
  grid-column-start: 22;
  grid-column-end: 26;
  position: relative;
}
.piano .key-8 {
  grid-column-start: 29;
  grid-column-end: 33;
}
.piano .key-10 {
  grid-column-start: 36;
  grid-column-end: 40;
  right: 15%;
}

.piano .key-3,
.piano .key-6 {
  left: 10%;
  position: relative;
}

.piano .key-1,
.piano .key-10 {
  right: 10%;
  position: relative;
}

.piano-key button:focus {
  outline: none;
}

.piano-key.white {
  z-index: 4;
  position: relative;
  bottom: var(--whitekey-height);
}

.piano-key.white .white-btn {
  background-color: ivory;
  height: 100%;
  width: var(--whitekey-width);
}

.piano-key .white-btn:active {
  background-color: rgb(206, 206, 206);
  border: 2px inset rgb(29, 35, 46);
  outline: 1px inset rgba(160, 144, 110, 0.4);
}

.piano-key.black {
  z-index: 5;
  height: var(--blackkey-height);
  position: relative;
}

.piano-key.black .black-btn {
  background-color: #000000;
  height: 100%;
  width: var(--blackkey-width);
}

.piano-key.white .white-btn,
.piano-key.black .black-btn {
  transition-duration: 300ms;
  transition-delay: 20ms;
  transition-property: background-color;
}

.piano-key button:focus,
.piano-key button:active {
  transition-duration: 80ms ease-out, 80ms linear, 100ms linear;
  transition-delay: 0ms, 0ms, 0ms;
  transition-property: background-color, border, outline;
}

.piano-key .black-btn:active {
  background-color: rgb(32, 32, 29);
  border: 2px inset rgb(52, 58, 71);
  /* outline: 4px inset rgba(122, 150, 182, 0.795); */
  outline: 1px inset rgb(66, 58, 58);
}
