.chromatic-container .chromatic {
  width: 90%;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-template-rows: 2em 2.5em 2.5em;
}

.chromatic .flarp-it-up {
  grid-column-start: 3;
  grid-column-end: span 2;
}

.chromatic .tick {
  margin: calc(90% / 12);
}

.chromatic .tick button {
  padding: 0em calc(90% / 20);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.chromatic .key-0,
.chromatic .key-1,
.chromatic .key-2,
.chromatic .key-3,
.chromatic .key-4,
.chromatic .key-5 {
  grid-row-start: 2;
  grid-row-end: 2;
}

.chromatic .key-6,
.chromatic .key-7,
.chromatic .key-8,
.chromatic .key-9,
.chromatic .key-10,
.chromatic .key-11 {
  grid-row-start: 3;
  grid-row-end: 3;
}
