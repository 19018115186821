/* Quiz Settings settings */
:root {
  --radius: 53vmin !important;
}

.input-choices {
  grid-area: middle;
}

.quiz-settings-container .options-header {
  color: ivory;
}

.quiz-settings-container .quiz-option {
  margin: 0.5em;
  padding: 0.5em;
  font-size: 1em;
}

.Quiz-main {
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: 30% 11% 7% 8% 44%;
  grid-template-areas:
    'imgl imgc'
    'skip imgc'
    'question question'
    'volume volume'
    'input input';
}

@media only screen and (max-device-width: 4000px) and (orientation: landscape) {
  .Quiz-main {
    grid-template-columns: 30% 16% 54%;
    grid-template-rows: 20% 40% 40%;
    grid-template-areas:
      'img skip volume'
      'img question input'
      'img score input';
  }
}

.loser {
  border-color: rgba(128, 14, 14, 1) !important;
}
.chromatic .loser,
.circleOfFifths .loser {
  border-width: 2px;
  border-style: solid;
}

.score-container {
  margin: auto;
  grid-area: imgl;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 96%;
}

.score {
  --button-text: calc(100vmin / 10);
  width: calc(var(--button-text) * 2);
  height: calc(var(--button-text) * 2);
  border: 1px solid rgba(23, 65, 86, 0.73);
  border-radius: 30%;
  background-color: #00000073;
  font-size: calc(var(--button-text) * 1.5);
  box-shadow: 0 8px 30px 10px rgba(4, 12, 14, 0.35);
}

.skip-container {
  grid-area: skip;
}

.sound-options {
  grid-area: volume;
  width: 100%;
  height: 100%;
}

.input-container + .sound-checkbox {
  font-size: 0.6em;
}

.sound-options,
.sound-checkbox,
.skip-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Skip-button:hover,
.circle button:hover {
  /* background: rgb(80, 127, 221); */
  color: rgb(255, 255, 255) !important;
}

.Skip-button {
  font-size: calc(var(--button-text) * 0.95) !important;
  font-weight: 800;
  font-style: oblique;
  padding: 0.3em !important;
  background: rgb(13 25 39 / 80%);
  border-radius: 15%/25% !important;
  border: 1px solid rgb(24 74 20 / 90%) !important;
  color: rgba(219, 112, 231, 0.65) !important;
  box-shadow: 0 8px 30px 10px rgba(4, 12, 14, 0.35);
}

.image-container {
  grid-area: imgc;
  display: flex;
  align-items: center;
  justify-content: center;
}

.image-container .debug {
  position: absolute;
  color: darkred;
}

.key-img {
  height: 95%;
  /* max-width: 100%; */
  /* max-height: calc(100vh - 3em); */
  object-fit: contain;
  pointer-events: none;
  grid-area: imgc;
}

@media (prefers-reduced-motion: no-preference) {
  .key-img {
    background-color: antiquewhite;
    padding: 1%;
    border-radius: 12px;
    border: 3px solid rgba(82, 46, 5, 0.397);
    box-shadow: 0 3px 10px 10px rgba(160, 144, 110, 0.1);
    /* animation: App-logo-spin infinite 20s linear; */
  }
}

.question-container .question {
  font-size: 0.8em;
  font-weight: normal;
  color: var(--text-off-white);
}

.question-container .question b {
  font-size: 1.25em;
}

.question-container {
  align-self: center;
  grid-area: question;
}

.circle-container + .question-container {
  align-self: center;
}

.input-container {
  grid-area: input;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-container.circle-container {
  grid-area: volume;
  grid-column: span 2;
  grid-row: span 2;
}

@media only screen and (max-device-width: 4000px) and (orientation: landscape) {
  .input-container.circle-container {
    grid-column: 3;
    grid-row: span 3;
  }
}

.keyboard-container .score-container,
.chromatic-container .score-container {
  position: relative;
  grid-column-start: 1;
  grid-column-end: span 6;
  grid-row-start: 1;
  grid-row-end: span 1;
}

/* Adjustments/ Corrections */

.score {
  box-sizing: content-box;
}
